import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-lewisville-texas.png'
import image0 from "../../images/cities/scale-model-of-history-n-mystery-ghost-tours-in-lewisville-texas.png"
import image1 from "../../images/cities/scale-model-of-turpin-park-in-lewisville-texas.png"
import image2 from "../../images/cities/scale-model-of-witherspoon-distillery-in-lewisville-texas.png"
import image3 from "../../images/cities/scale-model-of-the-colony-convention-&-visitors-bureau-in-lewisville-texas.png"
import image4 from "../../images/cities/scale-model-of-school-of-travel-and-tourism-in-lewisville-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Lewisville'
            state='Texas'
            image={image}
            lat='33.046233'
            lon='-96.99417399999999'
            attractions={ [{"name": "History N Mystery Ghost Tours", "vicinity": "127 W Main St, Lewisville", "types": ["point_of_interest", "establishment"], "lat": 33.0465621, "lng": -96.99479120000001}, {"name": "Turpin Park", "vicinity": "229 Turpin Dr, Lewisville", "types": ["park", "point_of_interest", "establishment"], "lat": 33.0825623, "lng": -97.04605579999998}, {"name": "Witherspoon Distillery", "vicinity": "225 S Charles St, Lewisville", "types": ["bar", "point_of_interest", "establishment"], "lat": 33.0447124, "lng": -96.99712439999996}, {"name": "The Colony Convention & Visitors Bureau", "vicinity": "6900 Main St, The Colony", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 33.0984113, "lng": -96.89228860000003}, {"name": "School of Travel and Tourism", "vicinity": "E, 405 TX-121 BUS, Lewisville", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 33.0341232, "lng": -96.99150029999998}] }
            attractionImages={ {"History N Mystery Ghost Tours":image0,"Turpin Park":image1,"Witherspoon Distillery":image2,"The Colony Convention & Visitors Bureau":image3,"School of Travel and Tourism":image4,} }
       />);
  }
}